import { render } from "./VehicleTypePeriods.vue?vue&type=template&id=539aec31"
import script from "./VehicleTypePeriods.vue?vue&type=script&lang=ts"
export * from "./VehicleTypePeriods.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QInput,QField,QIcon});
