import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { VehicleTypePeriod } from '@/types/vehicle-type-period'
import { getVehicleTypesPeriod } from '@/api/vehicle-type-period/getVehicleTypesPeriod'
import { deleteVehicleTypePeriod } from '@/api/vehicle-type-period/deleteVehicleTypePeriod'
import { createVehicleTypePeriod } from '@/api/vehicle-type-period/createVehicleTypePeriod'
import { updateVehicleTypePeriod } from '@/api/vehicle-type-period/updateVehicleTypePeriod'

interface UseVehicleTypePeriod {
  data: Ref<VehicleTypePeriod[]>
  fetchAll: () => Promise<VehicleTypePeriod[]>
  remove: (uuid: string) => Promise<null>
  add: (body: VehicleTypePeriod) => Promise<VehicleTypePeriod>
  update: (body: VehicleTypePeriod) => Promise<VehicleTypePeriod>
  loading: ComputedRef<Loading>
}

export function useVehicleTypePeriod(
  initFetchAll?: boolean
): UseVehicleTypePeriod {
  const data = ref<VehicleTypePeriod[]>([])
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<VehicleTypePeriod[]>((resolve, reject) => {
      getVehicleTypesPeriod()
        .then(({ data: vehicleTypeGroups }) => {
          data.value = vehicleTypeGroups
          resolve(vehicleTypeGroups)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteVehicleTypePeriod(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: VehicleTypePeriod) {
    state.create = true
    return new Promise<VehicleTypePeriod>((resolve, reject) => {
      createVehicleTypePeriod(body)
        .then(({ data: vehicleTypeGroup }) => {
          data.value.push(vehicleTypeGroup)
          resolve(vehicleTypeGroup)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: VehicleTypePeriod) {
    state.update = true
    return new Promise<VehicleTypePeriod>((resolve, reject) => {
      updateVehicleTypePeriod(body)
        .then(({ data: vehicleTypeGroup }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === vehicleTypeGroup.uuid) {
              return {
                ...item,
                ...vehicleTypeGroup,
              }
            }

            return item
          })
          resolve(vehicleTypeGroup)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    add,
    remove,
    update,
    loading: computed(() => state),
  }
}
