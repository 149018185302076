
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useVehicleType } from '@/composable/useVehicleType'
import { useVehicleTypePeriod } from '@/composable/useVehicleTypePeriod'
import {
  required as requiredRule,
  booleanTrueRequired,
  requiredNotNegative,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { VehicleTypePeriod } from '@/types/vehicle-type-period'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { addDays, addMonths, format } from 'date-fns'

export default defineComponent({
  name: 'MasterVehicleTypePeriods',

  components: {
    MasterTable,
    AppFieldDate,
  },

  setup() {
    const { loading: loadingStateVehicleTypes, data: vehicleTypes } =
      useVehicleType(true)

    const {
      loading: loadingStateVehicleTypePeriods,
      data: vehicleTypePeriods,
      add,
      update,
      remove,
    } = useVehicleTypePeriod(true)

    function createFormModel(): Partial<VehicleTypePeriod> {
      return {
        uuid: uuidv4(),
        vehicleTypeUuid: '',
        periodStart: format(new Date(), 'yyyy-MM-dd'),
        periodEnd: format(new Date(), 'yyyy-MM-dd'),
        customerAvailabilityLevel1: 0,
        customerAvailabilityLevel2: 0,
        customerAvailabilityLevel3: 0,
        customerAvailabilityLevel4: 0,
        comment: '',
      }
    }
    const model = ref<Partial<VehicleTypePeriod>>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const validPeriodRange = computed(() => {
      const start = model.value.periodStart as string
      const end = model.value.periodEnd as string
      return +new Date(start) <= +new Date(end)
    })

    const columns = computed(() => [
      {
        name: 'vehicleTypeUuid',
        label: 'Fordonstyp',
        align: 'left',
        field: 'vehicleTypeUuid',
        format: (vehicleTypeUuid: string) =>
          vehicleTypes.value.find((x) => x.uuid === vehicleTypeUuid)?.name ||
          '',
        sortable: true,
      },
      {
        name: 'periodStart',
        label: 'Periodstart',
        align: 'left',
        field: 'periodStart',
        format: (date: string) => format(new Date(date), 'yyyy-MM-dd'),
        sortable: true,
      },
      {
        name: 'periodEnd',
        label: 'Periodslut',
        align: 'left',
        field: 'periodEnd',
        format: (date: string) => format(new Date(date), 'yyyy-MM-dd'),
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel1',
        label: 'Kundtillgänglighet nivå 1',
        align: 'left',
        field: 'customerAvailabilityLevel1',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel2',
        label: 'Kundtillgänglighet nivå 2',
        align: 'left',
        field: 'customerAvailabilityLevel2',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel3',
        label: 'Kundtillgänglighet nivå 3',
        align: 'left',
        field: 'customerAvailabilityLevel3',
        sortable: true,
      },
      {
        name: 'customerAvailabilityLevel4',
        label: 'Kundtillgänglighet nivå 4',
        align: 'left',
        field: 'customerAvailabilityLevel4',
        sortable: true,
      },
      {
        name: 'comment',
        label: 'Kommentar',
        align: 'left',
        field: 'comment',
        sortable: true,
      },
    ])

    const loading = computed(() => {
      return {
        ...loadingStateVehicleTypes.value,
        ...loadingStateVehicleTypePeriods.value,
      }
    })

    const exportData = computed(() => {
      return vehicleTypePeriods.value.map((vehicleType) => {
        return {
          'Fordonstyp grupp': vehicleType.vehicleTypeUuid,
          Periodstart: vehicleType.periodStart,
          Periodend: vehicleType.periodEnd,
          'Kundtillgänglighet nivå 1': vehicleType.customerAvailabilityLevel1,
          'Kundtillgänglighet nivå 2': vehicleType.customerAvailabilityLevel2,
          'Kundtillgänglighet nivå 3': vehicleType.customerAvailabilityLevel3,
          'Kundtillgänglighet nivå 4': vehicleType.customerAvailabilityLevel4,
          Kommentar: vehicleType.comment || '',
        }
      })
    })

    const onCopyFormModel = (row: VehicleTypePeriod) => {
      model.value = row
      model.value.uuid = uuidv4()
      model.value.periodStart = format(
        addDays(new Date(row.periodEnd), 1),
        'yyyy-MM-dd'
      )
      model.value.periodEnd = format(
        addMonths(new Date(model.value.periodStart), 1),
        'yyyy-MM-dd'
      )
    }

    return {
      columns,
      model,
      requiredRule,
      requiredNotNegative,
      booleanTrueRequired,
      vehicleTypes,
      vehicleTypePeriods,
      loadingStateVehicleTypes,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
      validPeriodRange,
      onCopyFormModel,
    }
  },
})
